<script setup>
import { onClickOutside } from '@vueuse/core';
import { WdsAvatar, WdsListItem, WdsLogo, WdsPillSelect, WdsSheet } from '@wds/components';
import { commonValues } from '~/config/common';
import { useCurrencyStore } from '~/stores/currency';
import { useLanguageStore } from '~/stores/language';

const props = defineProps({
  logo: {
    type: [Object, String],
    required: false,
    default: 'hw-full',
  },
});

const slots = useSlots();
const myAccount = useAccount();
const { $tracking } = useNuxtApp();
const { useUrl } = useUrls();
const { isBelowMediumScreen } = useMq();
const languageStore = useLanguageStore();
const currencyStore = useCurrencyStore();

const isLoggedInMenuOpen = ref(false);
const loggedInButton = ref(null);
const loggedInMenu = ref(null);
const isHamMenuOpen = ref(false);
const hamBurguerButton = ref(null);
const hamBurguerMenu = ref(null);
const hasExtraContent = Object.keys(slots).includes('nav-extra-content');

const hasLoggedInUser = toValue(myAccount.hasLoggedInUser);
const loggedInUser = toValue(myAccount.getLoggedInUser);
const loggedInMenuItems = myAccount.getLoggedInMenuItems;

const getLogoParams = computed(() => {
  const logoAsset = props.logo?.asset || props?.logo || 'hw-full';
  const logoWidth = props.logo?.width || '203';
  const logoHeight = props.logo?.height || '45';

  return {
    asset: logoAsset,
    width: logoWidth,
    height: logoHeight,
  };
});

onClickOutside(hamBurguerMenu, () => toggleHamburguerMenu(false), { ignore: [hamBurguerButton] });
onClickOutside(loggedInMenu, () => toggleLoggedInMenu(false), { ignore: [loggedInButton] });

function toggleHamburguerMenu(toggle) {
  isHamMenuOpen.value = toggle === undefined ? !isHamMenuOpen.value : toggle;
}

function toggleLoggedInMenu(toggle) {
  isLoggedInMenuOpen.value = toggle === undefined ? !isLoggedInMenuOpen.value : toggle;
}

function openLanguagePicker() {
  languageStore.toggleLanguagePicker(true);
  if (!toValue(isBelowMediumScreen)) {
    toggleLoggedInMenu(false);
    toggleHamburguerMenu(false);
  }
}

function openCurrencyPicker() {
  currencyStore.toggleCurrencyPicker(true);
  if (!toValue(isBelowMediumScreen)) {
    toggleLoggedInMenu(false);
    toggleHamburguerMenu(false);
  }
}

function handleLoggedInMenuItemClick(menuItem) {
  if (menuItem === 'logout') {
    $tracking?.onLogoutClick();

    setTimeout(() => {
      navigateTo(useUrl.getLogoutPageUrlDynamic(), { external: true });
    }, 100);
  }
}

async function handleLoginClick() {
  if (hasLoggedInUser) {
    toggleLoggedInMenu();
  } else {
    const authUrl = `${useUrl.getPwaLangBaseUrl()}${commonValues.HOSTELWORLD_LOGIN_URL}`;

    $tracking?.onLoginClick();

    setTimeout(() => {
      navigateTo(authUrl, { external: true });
    }, 100);
  }
}
</script>

<template>
  <nav :class="{ 'extra-content': hasExtraContent }">
    <div class="nav-hw-brand">
      <a
        :href="useUrl.getStaticBaseURL()"
        :aria-label="commonValues.HOSTELWORLD"
        rel="noopener"
        data-testid="hw-brand-link"
      >
        <WdsLogo
          collection="brand"
          :title="commonValues.HOSTELWORLD"
          loading="eager"
          v-bind="getLogoParams"
        />
      </a>
    </div>
    <div
      v-if="hasExtraContent"
      class="nav-extra-content"
    >
      <slot name="nav-extra-content" />
    </div>
    <div class="nav-buttons-wrapper">
      <div class="nav-button login-menu">
        <WdsAvatar
          v-if="hasLoggedInUser"
          ref="loggedInButton"
          class="login-avatar"
          size="large"
          tabindex="0"
          :user-name="loggedInUser?.name"
          :avatar-u-r-l="loggedInUser?.photo"
          :show-text="false"
          @click="handleLoginClick"
          @keydown.enter="handleLoginClick"
        />
        <WdsPillSelect
          v-else
          icon-start="user-line"
          :aria-label="$t('t_A11YLOGINTOHWACCOUNT')"
          :title="$t('t_A11YLOGINTOHWACCOUNT')"
          data-testid="user-button"
          @click="handleLoginClick"
        />
        <div
          v-if="hasLoggedInUser && !toValue(isBelowMediumScreen) && isLoggedInMenuOpen"
          ref="loggedInMenu"
          class="button-popover login-popover"
        >
          <div class="login-menu-header">
            <WdsAvatar
              size="large"
              :user-name="loggedInUser?.name"
              :avatar-u-r-l="loggedInUser?.photo"
            />
          </div>
          <ul class="login-menu-body">
            <WdsListItem
              v-for="loggedInMenuItem in Object.keys(loggedInMenuItems)"
              :key="loggedInMenuItem"
              v-bind="loggedInMenuItems[loggedInMenuItem]"
              @click="handleLoggedInMenuItemClick(loggedInMenuItem)"
            />
          </ul>
        </div>
        <WdsSheet
          v-if="hasLoggedInUser"
          :is-open="toValue(isBelowMediumScreen) && isLoggedInMenuOpen"
          :sheet-height="0"
          :head-divider="false"
          @sheet-close="toggleLoggedInMenu(false)"
        >
          <template #sheetHeader>
            <div class="login-menu-header">
              <WdsAvatar
                size="large"
                :user-name="loggedInUser?.name"
                :avatar-u-r-l="loggedInUser?.photo"
              />
            </div>
          </template>
          <template #sheetBody>
            <ul class="login-menu-body">
              <WdsListItem
                v-for="loggedInMenuItem in Object.keys(loggedInMenuItems)"
                :key="loggedInMenuItem"
                v-bind="loggedInMenuItems[loggedInMenuItem]"
                @click="handleLoggedInMenuItemClick(loggedInMenuItem)"
              />
            </ul>
          </template>
        </WdsSheet>
      </div>
      <div class="nav-button hamburger-menu">
        <WdsPillSelect
          ref="hamBurguerButton"
          class="hamburger-button"
          icon-start="menu-line"
          :aria-label="$t('t_A11YBURGERMENU')"
          :title="$t('t_A11YBURGERMENU')"
          data-testid="hamburger-menu-button"
          @click="toggleHamburguerMenu()"
        />
        <div
          v-if="!toValue(isBelowMediumScreen) && isHamMenuOpen"
          ref="hamBurguerMenu"
          class="button-popover hamburger-popover"
        >
          <div class="hamburger-menu-header">
            <WdsPillSelect
              icon-start="globe"
              :text="languageStore?.getCurrentLanguage?.name"
              @click="openLanguagePicker"
            />
            <WdsPillSelect
              icon-start="money"
              :text="currencyStore?.getCurrentCurrency?.code"
              @click="openCurrencyPicker"
            />
          </div>
          <div class="hamburger-menu-body">
            <CommonHwNavBarSectionsHamburguerMenuLinks />
          </div>
        </div>
        <WdsSheet
          :is-open="toValue(isBelowMediumScreen) && isHamMenuOpen"
          @sheet-close="toggleHamburguerMenu(false)"
        >
          <template #sheetHeader>
            <div class="hamburger-menu-header">
              <WdsPillSelect
                icon-start="globe"
                :text="languageStore?.getCurrentLanguage?.name"
                @click="openLanguagePicker"
              />
              <WdsPillSelect
                icon-start="money"
                :text="currencyStore?.getCurrentCurrency?.code"
                @click="openCurrencyPicker"
              />
            </div>
          </template>
          <template #sheetBody>
            <div class="hamburger-menu-body">
              <CommonHwNavBarSectionsHamburguerMenuLinks />
            </div>
          </template>
        </WdsSheet>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  position: relative;
  display: grid;
  grid-template-areas: 'hw-brand nav-buttons';
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: $wds-spacing-s $wds-spacing-l;
  width: 100%;

  &.extra-content {
    grid-template-areas:
      'hw-brand nav-buttons'
      'extra extra';
  }

  .nav-hw-brand {
    grid-area: hw-brand;
    display: flex;
    align-items: center;
    height: 48px;

    a {
      display: inline-flex;
      width: wds-rem(200px);

      &:focus-visible {
        box-shadow:
          0 0 0 wds-rem(2px) $wds-color-white,
          0 0 0 wds-rem(4px) $wds-color-ink-darker;
        border-radius: wds-rem(6px);
        outline: none;
      }
    }
  }

  .nav-extra-content {
    grid-area: extra;
  }

  .nav-buttons-wrapper {
    grid-area: nav-buttons;
    display: flex;
    gap: $wds-spacing-s;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
    flex: 1;

    .nav-button {
      position: relative;
      display: inline-flex;

      .login-avatar {
        cursor: pointer;

        &:focus-visible {
          box-shadow:
            0 0 0 wds-rem(2px) $wds-color-white,
            0 0 0 wds-rem(4px) $wds-color-ink-darker;
          border-radius: 50%;
          outline: none;
        }

        :deep(.avatar-circle) {
          border: none;
        }
      }

      .button-popover {
        position: absolute;
        top: $wds-spacing-xxl;
        right: 0;
        background-color: $wds-color-white;
        z-index: $wds-z-index-dropdown;
        border-radius: $wds-border-radius-xl;
        box-shadow: $wds-shadow-light-l;
        min-width: wds-rem(300px);

        &.login-popover {
          padding: $wds-spacing-m;
        }
      }

      &.login-menu {
        .login-menu-header {
          @include body-1-bld;

          @include desktop {
            margin-bottom: $wds-spacing-m;
          }
        }

        .login-menu-body {
          display: flex;
          flex-direction: column;
          margin-bottom: $wds-spacing-m;

          @include desktop {
            margin-bottom: 0;
          }
        }
      }

      &.hamburger-menu {
        position: relative;

        .hamburger-menu-header {
          display: flex;
          gap: $wds-spacing-s;
        }

        .hamburger-menu-body {
          padding: 0;
        }

        > .hamburger-popover {
          .hamburger-menu-header {
            padding: $wds-spacing-s $wds-spacing-m;
            box-shadow: inset 0 -0.0625rem 0 0 #dddfe4;
          }

          .hamburger-menu-body {
            padding: $wds-spacing-s;
          }
        }
      }
    }
  }

  @include desktop {
    &.extra-content {
      grid-template-areas: 'hw-brand extra nav-buttons';
      grid-template-columns: auto 1fr auto;
    }
  }
}
</style>
